'use strict'
import settings from "@/settings";
import constants from "@/constants"
import calculateMixins from "@/mixins/calculateMixins";
import httpClient from "@/services/http.services";
import showErrorsMixins from "@/mixins/showErrorsMixins";
// import httpClient from "@/services/http.services";

const datatableMixins = {
    mixins: [calculateMixins, showErrorsMixins],
    emits: ['update-items'],
    data() {
        return {
            // modalDataIsLoading: false,
            confirmDeleteModalIsLoading: false,
            orderToWaitingData: null,
            changeOrderToWaitingModal: false,
            searchData: null,
            processedSearchValue: null,
            selectedCategory: null,
            selectedCategoryId: null,
            selectedType: null,
            selectedTypeId: null,
            clearLastSearchingValue: false,
            fromTimestamp: null,
            toTimestamp: null,
            warehouseItems: null,
            masters: null,
            responsibles: null,
            taxes: null,
            services: null,
            suppliers: null,
            employees: null,
            areas: null,
            disableEditButton: false,
            isLoading: true,
            dataTableIsLoading: false,
            sort: {
                column: 'id',
                direction: '-',
            },
            items: [],
            itemModal: false,
            itemDeleteModal: false,
            confirmItemDeleteModal: false,
            confirmItemsMultipleDeleteModal: false,
            item: {},
            // item: null,
            // updatedItem: {},
            updatedItemId: null,
            updatedItemsIds: [],
            itemToDelete: {},
            // submitted: false,
            pagination: {
                totalCount: null,
                pageCount: null,
                currentPage: 1,
                perPage: 25
            },
            addedItems: [],
            selectedItems: [],
            selectAllCheckbox: [],
            openedItemsIds: [],
            settings,
            constants,
            getItemsAfterRouteChange: true,
        }
    },
    watch: {
        '$route'() {
            if (this.$route.path === '/login' || this.$route.path !== this.routePath || !this.getItemsAfterRouteChange) {
                return false
            }
            this.checkRouteQuery() // ranshe eto bilo vishe  (if (this.$route.path === '/login)...
            this.getItems()
            // if(!this.isLoading) {
            //     this.getItems()
            // }

            // if(!this.isLoading) {
            //     this.getItems()
                // if (this.$route.query.search) {
                //     // this.getItems(false)
                //     this.getItems()
                // } else {
                //     this.getItems()
                // }
            // }
        },
        // selectedItems() {
        //     if (this.checkAllItemsAreSelected() && this.selectedItems.length) {
        //         this.selectAllCheckbox.push(0)
        //     } else {
        //         this.selectAllCheckbox = []
        //     }
        // },
        selectedItems() {    // this version in Messages.vue component
            this.checkAllItemsAreSelected()
        },
        '$store.state.firstLayerIsOpened'(value) {
            if (!value) {
                if (this.itemModal) {
                    this.closeItemModal()
                }
                if (this.confirmItemDeleteModal) {
                    this.closeConfirmItemDeleteModal()
                }
                if (this.confirmItemsMultipleDeleteModal) {
                    this.closeConfirmItemsMultipleDeleteModal()
                }
                if (this.changeOrderToWaitingModal) {
                    this.closeChangeOrderToWaitingModal()
                }
          }
        },
        'pagination.perPage'(value) {
            let perPage = value
            if (value > 200) {
                perPage = 200
            }
            if (this.$route.path === '/orders') {
                localStorage.setItem('ordersPerPage',perPage)
            }
            if (this.$route.path === '/offers') {
                localStorage.setItem('offersPerPage',perPage)
            }
        }
    },
    methods: {
        resetPaginationAndGetItems() {
            // this.resetRouteAndPagination()
            this.getItems()
        },
        changeDatesRange({ fromTimestamp, toTimestamp }, loadData = false) {
            this.fromTimestamp = fromTimestamp
            this.toTimestamp = toTimestamp
            this.resetCurrentPageData()
            if (loadData) {
                // console.log('load data')
                this.getItems()
            }
        },
        changeOrderStateToWaitingOnClick(orderData) {
            if (!orderData) return
            this.orderToWaitingData = orderData
            this.openChangeOrderToWaitingModal()
        },
        changeOrderStateToWaiting(obj) {
            if (!this.orderToWaitingData?.id) return
            // this.$refs['orderStateBtn-' + this.orderToWaitingData.id]?.changeOrderState(3, true, obj.staffComment).then(() => setTimeout(this.closeChangeOrderToWaitingModal(), 20000))
            this.$refs['orderStateBtn-' + this.orderToWaitingData.id]?.changeOrderState(3, true, obj.staffComment).then(() => this.closeChangeOrderToWaitingModal())
        },
        openChangeOrderToWaitingModal() {
            this.changeOrderToWaitingModal = true
            this.$store.commit('toggleFirstLayer', true)
        },
        closeChangeOrderToWaitingModal() {
            this.changeOrderToWaitingModal = false
            this.$store.commit('toggleFirstLayer', false)
        },
        changeProcessedSearchValue(value) {
            this.processedSearchValue = value
        },
        clearLastSearching() {
            // this.searchData = null
            this.clearLastSearchingValue = true
            setTimeout(() => {
                this.clearLastSearchingValue = false
            }, 10)
        },
        resetCurrentPageData() {
            this.pagination.totalCount = null
            this.pagination.pageCount = null
            this.pagination.currentPage = 1
            // this.pagination.perPage = 25

            const route = {
                route: this.$route.path,
                query: {
                    ...this.$route.query,
                }
            }

            delete route.query.page

            this.getItemsAfterRouteChange = false
            this.$router.push(route).then(() => this.getItemsAfterRouteChange = true)
        },
        resetRouteAndPagination() {
            this.pagination.currentPage = 1
            this.pagination.perPage = 25

            const route = {
                route: this.$route.path,
                query: {}
            }
            this.$router.push(route)

            this.searchData = null
            this.processedSearchValue = null
        },
        resetPagination() {
            this.pagination.currentPage = 1
            this.pagination.perPage = 25
        },
        closeAllAppLayers() {
            this.$store.commit('toggleFourthLayer', false)
            this.$store.commit('toggleThirdLayer', false)
            this.$store.commit('toggleSecondLayer', false)
            this.$store.commit('toggleFirstLayer', false)
            this.$store.commit('toggleDropdownOverlayVisibility', false)
        },
        generateThisMonthRange() {
            // console.log('generateThisMonthRange')
            let now = new Date()
            let firstDayOfCurrMonth = new Date(now.getFullYear(), now.getMonth(), 1, 0, 0, 0, 1)

            const calculatedFirstDayOfCurrMonth =  firstDayOfCurrMonth ? this.calculateDayStartDate(firstDayOfCurrMonth) : null
            const toDate = this.calculateDayEndDate(now)

            this.datesRange = [calculatedFirstDayOfCurrMonth, toDate]
            this.fromTimestamp = calculatedFirstDayOfCurrMonth ? +calculatedFirstDayOfCurrMonth / 1000 : null
            this.toTimestamp = toDate ? +toDate / 1000 : null
        },
        // calculateToDate(toTimestamp) {
        //     if (!toTimestamp) return false
        //     const toDate = new Date(toTimestamp)
        //     const year = toDate.getFullYear()
        //     const month = toDate.getMonth()
        //     const date = toDate.getDate() + 1
        //     const hours = toDate.getHours()
        //     const seconds = toDate.getSeconds()
        //     const milliseconds = toDate.getMilliseconds() - 1
        //     return new Date(year, month, date, hours, seconds, milliseconds)
        // },
        changeCustomerStatus(newStatus, userId) {
            if (!userId) return false
            const itemsWithSameUser = this.items?.filter(i => {
                return i.user_id === userId
            })

            if (itemsWithSameUser?.length) {
                itemsWithSameUser.forEach(i => {
                    if (i.customer) {
                        if (i.customer.customerStatus) {
                            i.customer.customerStatus.status_id = newStatus
                        } else {
                            i.customer.customerStatus = {}
                            i.customer.customerStatus.status_id = newStatus
                        }
                    }
                })
            }
        },
        // changeUpdatedItemId(id, updateItems = false) {
        // async changeUpdatedItemId(id, module = 'orders') {
        //     // if (!id || !updateItems) return false
        //     if (!id) return false
        //
        //     let foundItem = this.items?.find(item => item.id === id)
        //
        //     if (foundItem) {
        //         const indexOfFoundItem = this.items.indexOf(foundItem)
        //         if (indexOfFoundItem !== -1) {
        //             const itemData = await this.getItemById(id, module)
        //             if (itemData) {
        //                 this.items[indexOfFoundItem] = itemData
        //                 if (module === 'orders') {
        //                     this.constructOrdersTable()
        //                 } else if (module === 'offers') {
        //                     this.constructOffersTable()
        //                 }
        //             }
        //         }
        //         //     foundItem = {'name': 'aaaa'}
        //         // console.log('foundItem: ', foundItem)
        //     }
        // },
        // async getItemById(id, module) {
        //     try {
        //         const { data, status } = await httpClient.post(`${module}/get-${module}-by-id?expand=${this.fieldsExpand}`, { orderId: id })
        //         if (status === 200 && data?.success && data.item) {
        //             return data.item
        //         } else {
        //             return null
        //         }
        //     } catch(err) {
        //         this.showError(err)
        //     } finally {
        //         this.$store.commit('changeUpdatedOrderId', null)
        //     }
        // },
        generateUserEmailAccounts() {
            if (!this.$store.state.user.emailAccounts?.length) return false
            this.userEmailAccounts = this.$store.state.user.emailAccounts.map(account => (
                {id: account.accountData?.id, username: account.accountData?.username, sender_name: account.accountData?.sender_name, signature: account.signature, is_default_for_offers: account.accountData?.is_default_for_offers, is_default_for_invoices: account.accountData?.is_default_for_invoices}
            ))
        },
        toggleShowPositionTable(item, positionsEmpty = false) {
            if (positionsEmpty) return false
            item.showPositionsTable = !item.showPositionsTable
            this.openedItemsIds = this.items.filter(item => item.showPositionsTable)?.map(i => { return i.id })
        },
        calculateGrandTotal(item) {
            if (item.positions && item.positions.filter(position => position.status === 1).length) {
                return this.formatCurrency(+item.positions.filter(position => position.status === 1).reduce((sum, position) => sum + ((position.sell_price - ((position.discount / 100) * position.sell_price)) * position.qty) * (((position.tax ? position.tax.value : 0) / 100) + 1), 0) + (item.consumable ? ((item.consumable.sell_price - (((item.consumable.discount || 0) / 100) * item.consumable.sell_price)) * item.consumable.qty) * ((item.consumable.tax.value / 100) + 1) : 0))
            } else {
                return this.formatCurrency(0)
            }
        },
        // routeUpdated(getItems = true) {
        //     if (this.$route.path === '/login' || this.$route.path !== this.currentPath) {
        //         return false
        //     }
        //     if (getItems) {
        //         this.getItems()
        //     }
        // },
        // updateItems(item, showSpinner = false, clearItem = false) {
        //     // if (id) {
        //     //     this.updatedItemId = id
        //     // }
        //     if (item) {
        //         this.updatedItem = item
        //         this.updatedItemId = item.id
        //         // this.item = item
        //         // console.log(item.role)
        //     }
        //     if (clearItem) {
        //         this.item = {}
        //         this.submitted = false
        //     }
        //     this.getItems(showSpinner)
        // },
        updateItems(itemId, getItems = true, showSpinner = false, clearItem = false) {
            // console.log('update items', itemId)
            if (itemId) {
                this.updatedItemId = itemId
            }
            if (clearItem) {
                this.item = {}
                this.submitted = false
            }
            if (getItems) {
                this.getItems(showSpinner)
            }

            this.$emit('update-items') // Important: this is for update items in parent TAB component
        },
        closeItemModal() {
            this.itemModal = false
            // if (this.$store.state.firstLayerIsOpened) {
            this.$store.commit('toggleFirstLayer', false)
            // }
            // this.item = {}
            this.submitted = false
        },
        addNewItem() {
            this.item = {}
            this.submitted = false
            this.itemModal = true
            // if (!this.$store.state.firstLayerIsOpened) {
            this.$store.commit('toggleFirstLayer', true)
            // }
        },
        editItem(item) {
            this.item = {...item}
            this.itemModal = true
            // if (!this.$store.state.firstLayerIsOpened) {
            this.$store.commit('toggleFirstLayer', true)
            // }
        },
        confirmItemDelete(itemToDelete) {
            this.itemToDelete = {...itemToDelete}
            this.confirmItemDeleteModal = true
            this.$store.commit('toggleFirstLayer', true)
        },
        closeConfirmItemDeleteModal() {
            this.itemToDelete = {}
            this.confirmItemDeleteModal = false
            this.$store.commit('toggleFirstLayer', false)
        },
        confirmItemsMultipleDelete() {
            this.confirmItemsMultipleDeleteModal = true
            this.$store.commit('toggleFirstLayer', true)
        },
        closeConfirmItemsMultipleDeleteModal() {
            this.confirmItemsMultipleDeleteModal = false
            this.$store.commit('toggleFirstLayer', false)
        },
        async processDeleteItem(address, id) {
            if (!address || !id) return
            this.confirmDeleteModalIsLoading = true
            this.dataTableIsLoading = true
            this.disableEditButton = true
            try {
                const { status } = await httpClient.post(address, {id})
                if (status === 204) {
                    this.closeConfirmItemDeleteModal()
                    this.getItems()
                } else {
                    this.showUnknownError()
                }
            } catch(err) {
                this.showError(err)
            } finally {
                this.confirmDeleteModalIsLoading = false
                // this.dataTableIsLoading = false
                // this.disableEditButton = false
            }
        },
        checkRouteQuery() {
            if (this.$route.query.page) {
                this.pagination.currentPage = +this.$route.query.page
            } else {
                this.pagination.currentPage = 1
            }

            if (this.$route.query.perPage) {
                let perPage = +this.$route.query.perPage
                this.pagination.perPage = perPage > 200 ? 200 : perPage
                // this.pagination.perPage = +this.$route.query.perPage
            } else if (this.$route.path === '/orders' || this.$route.path === '/offers') {
                if (this.$route.path === '/orders') {
                    // this.pagination.perPage = localStorage.getItem('ordersPerPage') ? +localStorage.getItem('ordersPerPage') : 25
                    let localStorageOrdersPerPage = localStorage.getItem('ordersPerPage') ?  +localStorage.getItem('ordersPerPage') : 25
                    if (localStorageOrdersPerPage > 200) {
                        localStorageOrdersPerPage = 200
                    }
                    this.pagination.perPage = localStorageOrdersPerPage
                    if (this.pagination.perPage !== 25) {
                        this.$router.push({
                            path: this.$route.path,
                            query: {
                                ...this.$route.query,
                                page: 1,
                                perPage: this.pagination.perPage
                            }
                        })
                    }
                } else if (this.$route.path === '/offers') {
                    let localStorageOffersPerPage = localStorage.getItem('offersPerPage') ?  +localStorage.getItem('offersPerPage') : 25
                    if (localStorageOffersPerPage > 200) {
                        localStorageOffersPerPage = 200
                    }
                    this.pagination.perPage = localStorageOffersPerPage
                    if (this.pagination.perPage !== 25) {
                        this.$router.push({
                            path: this.$route.path,
                            query: {
                                ...this.$route.query,
                                page: 1,
                                perPage: this.pagination.perPage
                            }
                        })
                    }
                }
            } else {
                this.pagination.perPage = 25
            }

            // if (this.$route.path === '/orders' && this.pagination.perPage !== 25) {
            //     console.log(this.$route.path)
            //     this.pagination.perPage = +localStorage.getItem('ordersPerPage') ?? 25
            //     this.$router.push({
            //         path: this.$route.path,
            //         query: {
            //             ...this.$route.query,
            //             page: 1,
            //             perPage: this.pagination.perPage
            //         }
            //     })
            // } else if (this.$route.path === '/offers' && this.pagination.perPage !== 25) {
            //     this.pagination.perPage = +localStorage.getItem('offersPerPage') ?? 25
            //     this.$router.push({
            //         path: this.$route.path,
            //         query: {
            //             ...this.$route.query,
            //             page: 1,
            //             perPage: this.pagination.perPage
            //         }
            //     })
            // }


            if (this.$route.query.sort) {
                const firstSign = this.$route.query.sort[0]
                if (firstSign === '-') {
                    this.sort.direction = '-'
                    let sortColumn = this.$route.query.sort.split('')
                    this.sort.column = sortColumn.splice(1).join('')
                } else {
                    this.sort.direction = ''
                    this.sort.column = this.$route.query.sort
                }
            }

            if (this.$route.query.category) {
                this.selectedCategoryId = +this.$route.query.category
                const key = this.categoriesList[0].children.find(child => child.id === +this.$route.query.category)?.key
                if (key)  {
                    const obj = {}
                    obj[key] = true
                    this.selectedCategory = {...obj}
                } else {
                    this.selectedCategory = { 0: true }
                    this.selectedCategoryId = null
                }
            } else {
                this.selectedCategory = { 0: true }
                this.selectedCategoryId = null
            }

            if (this.$route.query.type) {
                this.selectedTypeId = +this.$route.query.type
                const type = this.ReducedPositionTypes?.find(pos => pos.id === this.selectedTypeId)
                if (type)  {
                    this.selectedType = type
                } else {
                    this.selectedType = null
                    this.selectedTypeId = null
                }
            } else {
                this.selectedType = null
                this.selectedTypeId = null
            }

            if (this.$route.query.search) {
                this.searchData = this.$route.query.search
                this.processedSearchValue = this.searchData
            } else {
                this.searchData = null
                this.processedSearchValue = null
            }

            if (this.$route.query.supplier) {
                this.selectedSupplierId = this.$route.query.supplier
            } else {
                this.selectedSupplierId = null
            }
        },
        checkAllItemsAreSelected() {
            if (!this.items) return false
            let allItemsAreSelected = true
            this.items.forEach(item => {
                if (!this.isItemSelected(item.id)) {
                    allItemsAreSelected = false
                }
            })

            if (allItemsAreSelected && this.selectedItems?.length) {
                this.selectAllCheckbox = [0]
            } else {
                this.selectAllCheckbox = []
            }
        },
        selectAllCheckboxOnClick() {
            if (this.selectAllCheckbox.length) {
                this.unselectAllItems()
            } else {
                this.selectAllItems()
            }
        },
        selectAllItems() {
            if (!this.items) return false
            this.items.filter(item => !this.isItemSelected(item.id)).forEach(item => this.selectedItems.push(item.id))
        },
        unselectAllItems(clearAllSelectedItems = false) {
            this.selectAllCheckbox = []
            this.showDetailedInfo = false
            if (clearAllSelectedItems) {
                this.selectedItems = []
            } else {
                if (!this.items) return false
                this.items.forEach(item => {
                    const index = this.selectedItems.indexOf(item.id)
                    if (index !== -1) {
                        this.selectedItems.splice(index, 1)
                    }
                })
            }
        },
        searchItems(searchData) {
            // console.log('searchItems')
            // if (searchData?.length <= 3) {
            //     searchData = null
            // }
            this.searchData = searchData

            // console.log(this.searchData, !this.searchData, 222)
            const route = {
                route: this.$route.path,
                query: {
                    ...this.$route.query,
                    search: this.searchData,
                }
            }
            if (!this.searchData) {
                // this.searchData = null
                this.searchData = null
                delete route.query.search
            }
            this.$router.push(route)
            // this.routeUpdated()
        },
        searchButtonOnClick() {
            const route = {
                route: this.$route.path,
                query: {
                    ...this.$route.query,
                    search: this.searchData,
                }
            }

            if (this.searchData !== this.$route.query.search) {
                this.isLoading = true
            }

            if (!this.searchData) {
                // this.searchData = null
                this.searchData = null
                delete route.query.search
            }

            this.$router.push(route)
        },
        resetSearch() {
            // if (this.searchData) {
            // console.log(this.$route.query.search)
            // this.searchData = null
            this.searchData = null
            if (this.$route.query.search) {
                const route = {
                    route: this.$route.path,
                    query: {
                        ...this.$route.query,
                        search: null,
                    }
                }
                delete route.query.search

                this.isLoading = true
                this.$router.push(route)
            }
                // this.getItems()
            // }
        },
        sortItems(columnName, changeRoute = true) {
            if (this.sort.column !== columnName) {
                this.sort.direction = ''
            } else {
                this.sort.direction = this.sort.direction === '' ? '-' : '';
            }
            this.sort.column = columnName

            if (changeRoute) {
                this.$router.push({
                    route: this.$route.path,
                    query: {
                        ...this.$route.query,
                        sort: this.sort.direction + this.sort.column
                    }
                })
                // this.routeUpdated()
            }
        },
        isItemUpdated(itemId) {
            return this.updatedItemId === itemId || this.updatedItemsIds.indexOf(itemId) !== -1
        },
        isItemAdded(itemId) {
            return this.addedItems.indexOf(itemId) !== -1
        },
        isItemSelected(itemId) {
            return this.selectedItems.indexOf(itemId) !== -1
        },
    },
    unmounted() {
        this.items = []
        this.isLoading = false
        // console.log('unmounted')
        this.closeAllAppLayers()
    },
    computed: {
        itemsArrayIsEmpty() {
            return !(this.items?.length)
        },
        allCurrentItemsAreAdded() {
            let result = true
            this.items?.forEach(item => {
                if (!this.isItemAdded(item.id)) {
                    result = false
                }
            })
            return result
        },
    }
}

export default datatableMixins
